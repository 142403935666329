import type { Typographies } from '../types';

export const fontFamily = {
  default: 'var(--font-family-default)',
  code: 'var(--font-family-code)',
};

export const typography: Typographies = {
  'display-1': {
    fontWeight: 600,
    fontSize: '3.75rem', // 60px
    lineHeight: '4.75rem', // 76px
  },
  'display-2': {
    fontWeight: 600,
    fontSize: '2.5rem', // 40px
    lineHeight: '3.25rem', // 52px
  },
  'display-3': {
    fontWeight: 600,
    fontSize: '1.75rem', // 28px
    lineHeight: '2.25rem', // 36px
  },
  'display-4': {
    fontWeight: 400,
    fontSize: '1.3125rem', // 21px
    lineHeight: '2rem', // 32px
  },
  'display-5': {
    fontWeight: 400,
    fontSize: '1.1875rem', // 19px
    lineHeight: '1.875rem', // 30px
  },
  'heading-1': {
    fontWeight: 550,
    fontSize: '1.5rem', // 24px
    lineHeight: '2rem', // 32px
  },
  'heading-2': {
    fontWeight: 550,
    fontSize: '1.3125rem', // 21px
    lineHeight: '2rem', // 32px
  },
  'heading-3': {
    fontWeight: 550,
    fontSize: '1.125rem', // 18px
    lineHeight: '1.75rem', // 28px
  },
  'heading-4': {
    fontWeight: 550,
    fontSize: '1.0625rem', // 17px
    lineHeight: '1.75rem', // 28px
  },
  'heading-5': {
    fontWeight: 550,
    fontSize: '1rem', // 16px
    lineHeight: '1.5rem', // 24px
  },
  'body-1': {
    fontWeight: 400,
    fontSize: '1rem', // 16px
    lineHeight: '1.5rem', // 24px
  },
  'body-2': {
    fontWeight: 400,
    fontSize: '0.9375rem', // 15px
    lineHeight: '1.5rem', // 24px
  },
  'body-3': {
    fontWeight: 400,
    fontSize: '0.875rem', // 14px
    lineHeight: '1.25rem', // 20px
  },
  'label-1': {
    fontWeight: 525,
    fontSize: '1rem', // 16px
    lineHeight: '1.5rem', // 24px
  },
  'label-2': {
    fontWeight: 525,
    fontSize: '0.9375rem', // 15px
    lineHeight: '1.5rem', // 24px
  },
  'label-3': {
    fontWeight: 525,
    fontSize: '0.875rem', // 14px
    lineHeight: '1.25rem', // 20px
  },
  'caption-1': {
    fontWeight: 500,
    fontSize: '0.8125rem', // 13px
    lineHeight: '1rem', // 16px
  },
  'caption-2': {
    fontWeight: 400,
    fontSize: '0.8125rem', // 13px
    lineHeight: '1rem', // 16px
  },
  'caption-3': {
    fontWeight: 500,
    fontSize: '0.75rem', // 12px
    lineHeight: '1rem', // 16px
  },
  'caption-4': {
    fontWeight: 400,
    fontSize: '0.75rem', // 12px
    lineHeight: '1rem', // 16px
  },
  'snippet-1': {
    fontWeight: 400,
    fontSize: '0.875rem', // 14px
    lineHeight: '1.25rem', // 20px
  },
  'snippet-2': {
    fontWeight: 400,
    fontSize: '0.8125rem', // 13px
    lineHeight: '1rem', // 16px
  },
  'snippet-3': {
    fontWeight: 400,
    fontSize: '0.75rem', // 12px
    lineHeight: '1rem', // 16px
  },
};

export const typographyExtra = {
  'header-title': {
    fontWeight: 500,
    fontSize: '1rem', // 16px
    lineHeight: '2.4rem', // 36px
  },
  'body-title': {
    fontWeight: 600,
    fontSize: '1.1875rem', // 19px
    lineHeight: '2.358rem', // 35.552px
  },
  'body-subtitle': {
    fontWeight: 400,
    fontSize: '1rem', // 16px
    lineHeight: '2.4rem', // 36px
  },
  'body-label-default': {
    fontWeight: 500,
    fontSize: '1rem', // 16px
    lineHeight: '2.4rem', // 36px
  },
  'body-label-compact': {
    fontWeight: 500,
    fontSize: '0.9375rem', // 15px
    lineHeight: '2.1333rem', // 25.6px
  },
};
